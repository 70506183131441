
import '../styles/app.css';
import { FiChevronDown } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";

export const NavbarItems = [
    {
        title: 'Abstract',
        path: '/Abstract',
        id: '1'
    },
    {
        title: 'Introduction',
        path: '/Introduction',
        id: '2',
        iconOpen: < FiChevronRight style={{color: "#459fbf",fontSize: "28px"}} />,
        iconClosed: < FiChevronDown style={{color: "#459fbf",fontSize: "28px"}}  />,
        // subNav: [
        //     {
        //         title: 'Key Components',
        //         path: 'Introduction/KeyComponents', 
        //         class: "KeyComponents",
        //         id: "KeyComponents",
        //     },
        //     {
        //         title: 'System Overview',
        //         path: 'SystemOverview',
        //         class: "SystemOverview" 
        //     },
        // ]
    },
    {
        title: 'The Oxygen-chain OO2',
        path: '/OxygenChain',
        id: '3',
        iconOpen: < FiChevronRight style={{color: "#459fbf",fontSize: "28px"}} />,
        iconClosed: < FiChevronDown style={{color: "#459fbf",fontSize: "28px"}} />,
        // subNav: [
        //     {
        //         title: 'Participants',
        //         path: '/OxygenChain/Participants',
        //         class: "Participants" 
        //     },
        //     {
        //         title: 'Blockchain',
        //         path: '/OxygenChain/Blockchain', 
        //         class: 'Blockchain'
        //     },
        //     {
        //         title: 'Appliances & Applications',
        //         path: '/OxygenChain/AppliancesApplications', 
        //         class: 'AppApp'
        //     },
        // ]
    },
    {
        title: 'Proof-of-Recycling',
        path: '/ProofOfRecycling',
        id: '4',
        iconOpen: < FiChevronRight style={{color: "#459fbf",fontSize: "28px"}} />,
        iconClosed: < FiChevronDown style={{color: "#459fbf",fontSize: "28px"}} />,
        // subNav: [
        //     {
        //         title: 'Security and Speed',
        //         path: '/ProofOfRecycling/SecurityAndSpeed',
        //         class: 'SecurityAndSpeed' 
        //     },
        //     {
        //         title: 'Constructing the proof',
        //         path: '/ProofOfRecycling/ConstructingTheProof',
        //         class: 'ConstructingTheProof' 
        //     },
        //     {
        //         title: 'Scoring',
        //         path: '/ProofOfRecycling/Scoring',
        //         class: 'Scoring' 
        //     },
        //     {
        //         title: 'Verifying the proof',
        //         path: '/ListItem6/VerifyingTheProof',
        //         class: 'VerifyingTheProof' 
        //     },
        // ]
    },
    {
        title: 'Transactions',
        path: '/Transactions',
        id: '5',
        iconOpen: < FiChevronRight style={{color: "#459fbf",fontSize: "28px"}} />,
        iconClosed: < FiChevronDown style={{color: "#459fbf",fontSize: "28px"}} />,
        // subNav: [
        //     {
        //         title: 'Staking fees',
        //         path: '/Transactions/StakingFees',
        //         class: 'StakingFees' 
        //     },
        //     {
        //         title: 'Transaction fees',
        //         path: '/Transactions/TransactionFees', 
        //         class: 'TransactionFees'
        //     },
        //     {
        //         title: 'Staking File Nodes',
        //         path: '/Transactions/StakingFileNodes',
        //         class: 'StakingFileNodes' 
        //     },
        //     {
        //         title: 'Incentives',
        //         path: '/Transactions/Incentives',
        //         class: 'Incentives' 
        //     },
        //     {
        //         title: 'Slashing',
        //         path: '/Transactions/Slashing', 
        //         class: 'Slashing'
        //     },
        // ]
    },
    {
        title: 'Oxygen Consensus Protocol',
        path: '/ConsensusProtocol',
        id: '6',
    },
    {
        title: 'Governance',
        path: '/Governance',
        id: '7',
        iconOpen: < FiChevronRight style={{color: "#459fbf",fontSize: "28px"}} />,
        iconClosed: < FiChevronDown style={{color: "#459fbf",fontSize: "28px"}} />,
        // subNav: [
        //     {
        //         title: 'DAO',
        //         path: '/Governance/DAO',
        //         class: 'DAO'
        //     }, 
        // ]
    },
]

export default NavbarItems