import React, {Children, useState} from 'react';
import styled from 'styled-components';
import { FiCornerRightDown } from "react-icons/fi";


const MobileToc = styled.div`
    position: absolute;
    background: #f1f1f1;
    z-index: 3;
    width: 100%;
    top:8rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    right: 0rem;
    display: flex;
    flex-direction: column ;
    text-align: center;
    transition: 300ms ease-in-out;
    @media (min-width:1480px) {
        top:8rem;
        right: 10rem;
        background: unset;
        width: 200px;
        display: none;
    }
    
`

const Toc = styled.div `
    position: absolute;
    background: #f1f1f1;
    z-index: 3;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column ;
    text-align: center;
    transition: 300ms ease-in-out;
    top:12rem;
    right: 10rem;
    background: unset;
    width: 200px;
    @media (max-width:1480px) {
        top:10rem;
        right: 3rem;
        background: unset;
        width: 200px;
    }
    @media (max-width:768px) {
        display: none;
    }
`
const PageMenu = styled.div`
    display: flex;
    z-index: 4;
    align-items: center;
    justify-content: right;
    padding: 1rem 2rem;
    position: absolute;
    top:8vh;
    height: 4rem;
    width: 100%;
    background: #050f13;
    left: 0rem;
    @media (min-width:768px) {
        color: black;
        background:transparent;
        width: unset;
        right: 3rem;
        background: unset;
        width: 200px;
        top:14vh;
        left: unset;
    }
    @media (min-width:1480px) {
        top:12vh;
        right: 10rem;
    }
`
const PageMenuText = styled.p`
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Lato';
    margin-right: 8px;
    text-align: left;
    color: white;
    font-weight: 400;
    @media (min-width:800px) {
        color: black;
    }
`
export const OnThisPage = styled.p`
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Lato';
    text-align: justify;
    color: #808080;
    margin-top: 0px;
    margin-bottom: 32px;
    width: 100%;
    line-height: 1.50;
`
export const PageHeader = styled.p`
    font-size: 16px;
    width: 100%;
    text-decoration: none;
    font-family: 'Lato';
    text-align: justify;
    color: #808080;
    margin-top: 0px;
    width: 100%;
    &:hover {
        color: #050f13;
        text-decoration: none;
        font-weight: 800;
    }
`  

const TOC = ({children}) => {
    const [pageMenu, setPageMenu] = useState(false);
    
    const openMenu = () => setPageMenu(!pageMenu);
    const closeMenu = () => setPageMenu(!pageMenu);

    return(
        <>
            <PageMenu onClick={openMenu}>
                <PageMenuText>On this page</PageMenuText>
                < FiCornerRightDown style={{color: "white",fontSize: "18px"}}/>
            </PageMenu>
            {
                pageMenu
                ?
                <MobileToc onClick={closeMenu} >
                    {children}
                </MobileToc>               
                :
                <MobileToc style={{top: "-30vh"}}>{children}</MobileToc>
            }
            <Toc>{children}</Toc>
        </>
    )
}

export default TOC;