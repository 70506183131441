import React from 'react';
import '../styles/app.css';
import styled from 'styled-components';
import Logo from '../img/oxygen-docs.svg';

const Container = styled.div`
    height: 8vh;
    position: sticky;
    top: 0vh;
    z-index: 999;
    left:0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background: var(--background-color);
    @media (min-width:1480px) {
        padding-left: 7rem;
    }
`

const Header = () => {
    return(
        <Container className="header">
            <img src={Logo} alt="" className="docsLogo"/>
        </Container>
    )
}

export default Header