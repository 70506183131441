import React from 'react';
import './styles/app.css'
import Header from './components/header.js'
import Navbar from './components/navbar.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Abstract from './Pages/Abstract.js';
import Introduction from './Pages/Introduction.js'
import OxygenChain from './Pages/TheOxygenBlockchain.js'
import ProofOfRecycle from './Pages/ProofOfRecycle.js';
import Transactions from './Pages/Transaction.js'
import TheOxygenProtocol from './Pages/TheOxygenProtocol.js'
import Governance from './Pages/Governance';
import styled from 'styled-components';

const NavLayout = styled.div`
  display: flex;
`
const Content = styled.div`
  height: 92vh;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 20rem 2rem 5rem;
  @media (max-width:768px) {
        padding: 2rem 2rem;
  }
  @media (min-width:1480px) {
        padding: 2rem 30rem 2rem 10rem;
    }
`

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <NavLayout>
          <Navbar/>
          <Content>
            <Switch>
                <Route path="/" exact component={Abstract}/>
                <Route path="/Abstract" exact component={Abstract}/>
                <Route path="/Introduction" exact component={Introduction}/>
                <Route path="/Introduction/KeyComponents" exact component={Introduction}/>
                <Route path="/OxygenChain" exact component={OxygenChain}/>
                <Route path="/ProofOfRecycling" exact component={ProofOfRecycle} />
                <Route path="/Transactions" exact component={Transactions} />
                <Route path="/ConsensusProtocol" exact component={TheOxygenProtocol} />
                <Route path="/Governance" exact component={Governance} />
            </Switch> 
          </Content>
        </NavLayout>
      </Router> 
        {/* <MainLayout /> */}
    </div>
  );
}

export default App;
