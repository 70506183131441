import React, {useState, useEffect}from 'react';
import {Title, Subtitle, SmallTitle,  Paragraph, ListItem, Toc, OnThisPage, PageHeader} from '../components/Typography.js'
import MarkDown from 'markdown-to-jsx';
import TOC from '../components/TOC.js';


const OxygenChain = () => {
    const currentPage = 'OxygenChain.md';
    const [page, setPage] = useState('');

    const markdownOptions = {
        forceBlock: true,
        overrides: {
            h1: Title,
            h2: Subtitle,
            h3: SmallTitle,
            h4: OnThisPage,
            h5: PageHeader,
            p: Paragraph, 
            li: ListItem,
            div: TOC, 
        },
    };

    useEffect(() => {
        import(`../Pages/Content/${currentPage}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPage(res));
            })
            .catch(err => console.log(err));
    });

    return (
            <MarkDown options={markdownOptions} >
                {page}
            </MarkDown>
    )
}

export default OxygenChain