import React, {useState, useEffect}from 'react';
import {Title, Paragraph, ListItem} from '../components/Typography.js'
import MarkDown from 'markdown-to-jsx';


const Abstract = () => {
    const currentPage = 'abstract.md';
    const [page, setPage] = useState('');

    const markdownOptions = {
        forceBlock: true,
        overrides: {
            h1: Title,
            p: Paragraph,
            li: ListItem, 
        },
    };

    useEffect(() => {
        import(`../Pages/Content/${currentPage}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPage(res));
            })
            .catch(err => console.log(err));
    });

    return (
            <MarkDown options={markdownOptions} >
                {page}
            </MarkDown>
    )
}

export default Abstract