import React, {useState} from 'react';
import '../styles/app.css';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import  {NavbarItems} from './navbarItems.js';
import Submenu from './subMenu.js'
import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";

const Container = styled.div`
    height: 92vh;
    width: 400px;
    position: sticky;
    top: 8vh;
    left: 0;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 3rem 2rem;
    transition: 300ms ease-in-out;
    @media (max-width:768px) {
        position: absolute;
        z-index: 998;
        background: #f1f1f1;
        width: 100%;
        top:8vh; 
        height: 92vh;
    }
    @media (min-width:1480px) {
        top:8rem;
        right: 10rem;
        min-width: 300px;
        margin-left: 5rem
    }
`
const MenuIcon = styled.div`
    z-index: 999;
    position: fixed;
    height: 8vh;
    display: none;
    align-items: center;
    top: 0;
    right:2rem;
    @media (max-width:768px) {
        display: flex;
    }
`

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);


    return(
        <>
            <MenuIcon onClick={handleClick}>
                {
                    click
                    ?
                    < FiX style={{color: "#459fbf",fontSize: "28px"}}  />
                    :
                    < FiMenu style={{color: "white",fontSize: "28px"}}  />
                }
            </MenuIcon>
            {
                click
                ?
                <Container className="navbar">
                    {NavbarItems.map((item, index) => {
                        return <Submenu handleClick={handleClick} click={click} setClick={setClick} item={item} key={index} />
                    })}
                </Container>
                :
                <Container className="navbar" style={{left: "-100%"}}>
                {NavbarItems.map((item, index) => {
                    return <Submenu click={click} setClick={setClick} item={item} key={index} />
                })}
                </Container>
            }
        </>
    )
}

export default Navbar