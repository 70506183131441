import React, {useState} from 'react';
import '../styles/app.css'
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { Link as Zelda} from "react-scroll";
import {NavbarItems} from './navbarItems';
import '../styles/app.css'
import { motion } from "framer-motion"
import {Active} from '../components/Typography.js'

const Container = styled(motion.div)`
    margin-bottom: 8px;
    &:hover {
        border-left: 4px solid var(--primary-color);
        transition: 100ms ease-in-out;
        cursor: pointer;
        padding-left: 1rem;
        color: black;
    }
`
const NavbarLink = styled(Link)`
    display:flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    font-size: 1rem;
    font-family: 'Lato';
    font-weight: 400;
    padding-left: 0rem;
    color: #808080;
    padding-right: 2rem;
    list-style: none;
    text-decoration: none;
    list-style: none;
    &:hover {
        transition: 100ms ease-in-out;
        cursor: pointer;
        color: black;
        font-weight: 700;
    } 
`
const NavbarLabel = styled.p`
    display:flex;
    width: 100%;
`
// const NavbarSubLabel = styled.p`
//     display:flex;
//     color: black;
//     width: 100%;
// `
// const DropdownLink = styled(Zelda)`
//     display:flex;
//     width: 100%;
//     justify-content: flex-start;
//     align-items: center;
//     height: 40px;
//     font-size: 1rem;
//     font-family: 'Lato';
//     padding-left: 4rem;
//     transition: 800ms ease-in-out;
//     text-decoration: none;
//     color: black;
//     list-style: none;
//     &:hover {
//         padding-left: 3rem;
//         background: #DFDFDF;
//         transition: 50ms ease-in;
//         cursor: pointer;
//     }
// `

const SubMenu = ({item, index, setClick, click}) => {
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav)

    const closeMobileMenu = () => setClick(!click);

    return (
        <Container transition={{ duration: 1 }} initial={false}>
            {/* {
                item.subNav
                ?
                <NavbarLink key={index} onClick={item.subNav && showSubnav}>
                    <NavbarLabel>{item.title}</NavbarLabel>
                    {item.subNav && subnav ? item.iconOpen : item.subNav ? item.iconClosed : null }
                </NavbarLink>
                :
                <NavbarLink to={item.path} key={index} onClick={item.subNav && showSubnav}>
                    <NavbarLabel>{item.title}</NavbarLabel>
                    {item.subNav && subnav ? item.iconOpen : item.subNav ? item.iconClosed : null }
                </NavbarLink>
            } */}
                {
                    window.location.pathname === item.path
                    ?
                    <Active>
                        <NavbarLink 
                            id={window.location.pathname === item.path ? "active" : ""} 
                            to={item.path} 
                            key={index} 
                            onClick={/* item.subNav && showSubnav && */ closeMobileMenu}>
                            <NavbarLabel>
                                {item.title}
                            </NavbarLabel>
                            {item.subNav && subnav ? item.iconOpen : item.subNav ? item.iconClosed : null }
                        </NavbarLink>
                    </Active>
                    :
                    <NavbarLink 
                            id={window.location.pathname === item.path ? "active" : ""} 
                            to={item.path} 
                            key={index} 
                            onClick={/* item.subNav && showSubnav && */ closeMobileMenu}>
                            <NavbarLabel>
                                {item.title}
                            </NavbarLabel>
                            {item.subNav && subnav ? item.iconOpen : item.subNav ? item.iconClosed : null }
                    </NavbarLink>


                }
            {/* <>
                {subnav && item.subNav.map((item,index) => {
                    return (
                        <DropdownLink 
                        to={item.id} 
                        key={index} transition={{ duration: 1 }} 
                        initial={false}
                        rel="stylesheet" 
                        href="" 
                        activeClass="active"
                        spy={true}
                        smooth={false}
                        offset={-100}
                        duration={500}
                        onClick={closeMobileMenu}
                        >
                            <NavbarSubLabel>{item.title}</NavbarSubLabel>
                        </DropdownLink>
                    )
                })}
            </> */}
        </Container>
    )
}

export default SubMenu