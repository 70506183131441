
import styled from 'styled-components';
import { FiHexagon } from "react-icons/fi";
import { FiDroplet } from "react-icons/fi";


const marginTop = { large: "64px", medium: "16px", small: "0px",  "default": "24px" } 
const marginBottom = { large: "40px", medium: "16px", small: "0px",  "default": "24px" } 


export const Title = styled.p`
    font-size: 2rem;
    color: #050f13;
    font-family: 'Bungee';
    margin-top: ${props => marginTop[props.marginTop] || marginTop["default"]};
    margin-bottom: 48px;
    @media (max-width:768px) {
        padding-top: 48px;
    }
` 
export const SmallTitle = styled.h3`
    font-size: 1.16rem;
    color: #050f13;
    font-family: 'Bungee';
    margin-top: ${props => marginTop[props.marginTop] || marginTop["default"]};
    margin-bottom: ${props => marginBottom[props.marginBottom] || marginBottom["default"]};
`

export const Paragraph = styled.p`
    font-size: 16px;
    font-family: 'Lato';
    text-align: justify;
    margin-top: ${props => marginTop[props.marginTop] || marginTop["default"]};
    margin-bottom: ${props => marginBottom[props.marginBottom] || marginBottom["default"]};
    width: 100%;
    line-height: 1.50;
` 
export const Subtitle = styled.h2`
    font-size: 1.65rem;
    display: inline-block;
    color: #050F13;
    font-weight: bold;
    font-family: 'Lato';
    margin-top: 80px;
    margin-bottom: ${props => marginBottom[props.marginBottom] || marginBottom["default"]};
` 
export const ListItemContainer = styled.div`
    display:flex;
    align-items: center;
` 
export const ListNumber = styled.li`
    font-size: 16px;
    font-family: 'Lato';
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 4px 9px;
    font-size: 20px;
    margin-right: 16px;
    border-radius: 100px;
    border: 4px solid #459fbf;
    @media (max-width:768px) {
        padding: 0px;
    }
`
export const Toc = styled.div`
    position: absolute;
    border-radius: 10px;
    // background: #f1f1f1f1;
    width: 200px;
    top:8rem;
    right: 2rem;
    @media (min-width:1480px) {
        top:8rem;
        right: 10rem;
    }
`
export const OnThisPage = styled.p`
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Lato';
    text-align: justify;
    color: #808080;
    margin-top: 0px;
    margin-bottom: 32px;
    width: 100%;
    line-height: 1.50;
`
export const PageHeader = styled.p`
    font-size: 16px;
    text-decoration: none;
    font-family: 'Lato';
    text-align: left;
    color: #808080;
    margin-top: 0px;
    margin-bottom: ${props => marginBottom[props.marginBottom] || marginBottom["default"]};
    width: 100%;
    &:hover {
        color: #050f13;
        text-decoration: none;
        font-weight: 800;
    }
`  
const ActiveContainer = styled.div`
    display:flex;
    align-items: center;
    &:hover {
        transition: 100ms ease-in-out;
        cursor: pointer;
        padding-left: 0rem;
        color: black;
        font-weight: 700;
    }
`

export const Active = ({children}) => {
    return(
        <ActiveContainer>
            <FiHexagon style={{color: "#459fbf",fontSize: "24px", marginRight: "1rem"}}/>
            {children}
        </ActiveContainer>
    )
}

export const ListItem = ({children}) => {
    return(
        <ListItemContainer>
            <FiDroplet style={{color: "#459fbf",fontSize: "24px",marginRight: "16px"}}/>
            <Paragraph marginBottom="small" marginTop="small">{children}</Paragraph>
        </ListItemContainer>
    )
}

export const SubListItem = ({children}) => {
    return(
        <ListItemContainer>
            <FiHexagon style={{color: "#4F4F4F",fontSize: "20px",marginRight: "16px",marginLeft: "120px"}}/>
            <Paragraph marginBottom="small" marginTop="small" style={{color: "#4F4F4F"}}>{children}</Paragraph>
        </ListItemContainer>
    )
}

export const NumberedListItem = ({children, Number}) => {
    return(
        <ListItemContainer>
            <ListNumber Number={1,2,3,4,5,6,7} style={{marginLeft: "40px"}}>{Number}</ListNumber>
            <Paragraph marginBottom="Small" marginTop="Small">{children}</Paragraph>
        </ListItemContainer>
    )
}